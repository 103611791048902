@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montez&family=The+Nautigal&display=swap");
// Navbar
.nav {
  font-size: 2rem;
  background-color: var(--clr-primary-2);
  opacity: 50;
  color: var(--clr-primary-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  height: 7rem;
  font-weight: 400;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1rem 1rem 1rem var(--clr-primary-1);

  animation: FadeIn 1.5s ease-in;

  &-logo {
    border-radius: 5px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1644686001/staytonEngineering/SElogo-800_qynby1.jpg);
    width: 25rem;
    height: 6.6rem;
    margin-left: 7rem;
    font-size: 4rem;
    cursor: pointer;

    &:hover,
    &:active {
      color: var(--clr-primary-1);
    }
  }

  &-list {
    display: flex;
    list-style: none;
    cursor: pointer;
    margin-right: 7rem;

    &-link {
      margin-right: 3rem;
      display: inline-block; // Needed so padding will and shifting right animation will work
      padding: 1rem 2rem;
      text-decoration: none;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        var(--clr-primary-1) 50%
      );
      background-size: 235%; // Needed to make the below animation work.  Shifting the bg color for the animation.
      transition: all 0.5s;

      &:hover,
      &:active {
        border-radius: 2px;
        background-position: 100%;
        color: var(--clr-primary-2);
        transform: translateX(1rem);
      }
    }
  }
}

.hamburger {
  height: 4px;
  width: 90%;
  background-color: var(--clr-primary-1);
  border-radius: 10px;
}

#nav-toggle-btn {
  display: none;
  width: 35px;
  height: 35px;
  // position: relative;
  margin-right: 5rem;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-toggle-btn span {
  display: block;
  position: absolute;
  height: 4px;
  width: 55%;
  background-color: var(--clr-primary-1);
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-toggle-btn span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-toggle-btn span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-toggle-btn span:nth-child(1),
#nav-toggle-btn span:nth-child(2) {
  top: 0px;
}

#nav-toggle-btn span:nth-child(3),
#nav-toggle-btn span:nth-child(4) {
  top: 13px;
}

#nav-toggle-btn span:nth-child(5),
#nav-toggle-btn span:nth-child(6) {
  top: 26px;
}

#nav-toggle-btn.open span:nth-child(1),
#nav-toggle-btn.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-toggle-btn.open span:nth-child(2),
#nav-toggle-btn.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-toggle-btn.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-toggle-btn.open span:nth-child(2) {
  left: calc(75% - 8px);
  top: 7px;
}

#nav-toggle-btn.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-toggle-btn.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-toggle-btn.open span:nth-child(5) {
  left: 5px;
  top: 20px;
}

#nav-toggle-btn.open span:nth-child(6) {
  left: calc(75% - 8px);
  top: 20px;
}

// Animation
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes FadeIn {
  0% {
    -moz-opacity: 0;
  }
  100% {
    -moz-opacity: 1;
  }
}
@-webkit-keyframes FadeIn {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}
@-o-keyframes FadeIn {
  0% {
    -o-opacity: 0;
  }
  100% {
    -o-opacity: 1;
  }
}
@-ms-keyframes FadeIn {
  0% {
    -ms-opacity: 0;
  }
  100% {
    -ms-opacity: 1;
  }
}

// Media queries
@media (max-width: 1000px) {
  #nav-toggle-btn {
    display: block;
  }

  .nav-logo {
    width: 28rem;
    background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1644686472/staytonEngineering/SElogo-500-cropped_vyx2og.jpg);
  }

  .nav-list {
    display: none;
  }
}

@media (max-width: 500px) {
  .nav {
    &-logo {
      margin-left: 2rem;
      font-size: 4.5rem;
    }
  }
  #nav-toggle-btn {
    margin-right: 2rem;
    margin-top: 0.3rem;
  }
}
