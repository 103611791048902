.industries {
  margin: 20rem 0;

  &-list {
    background-color: rgb(238, 238, 238);
    padding: 3rem 5rem 0 5rem;
  }

  p {
    font-size: 3rem;
    text-align: center;
    margin: 2rem;
    width: 22rem;
    color: var(--clr-primary-1);
  }

  &-header {
    color: var(--clr-secondary-1);
    margin-top: 5rem;
    margin-bottom: 10rem;
    text-align: center;
    font-size: 3rem;
    animation: FadeIn 1.5s ease-in;
  }

  &-list-1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 13rem;
  }

  &-list-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 13rem;
  }

  &-list-3 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 13rem;
  }
}

@media (max-width: 500px) {
  .industries {
    p {
      font-size: 4rem;
      width: 30rem;
      text-align: center;
    }
    &-header {
      font-size: 4rem;
    }
    &-list-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 2rem;
    }

    &-list-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 2rem;
    }

    &-list-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 13rem;
    }
  }
}
