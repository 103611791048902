@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=The+Nautigal&display=swap");

:root {
  /* bg color nav*/
  --clr-primary-1: rgb(22, 22, 85);
  /* primary background */
  --clr-primary-2: white;
  /* secondary background */
  --clr-secondary-1: grey;
  /* primary font color */
  --clr-secondary-2: lightgrey;
  /* secondary font background */
  --clr-font-secondary: #73cca8;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // set font-size to 10px vs 16px
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 40.25em) {
  html {
    font-size: 40%;
  }
}
@media (max-width: 30.25em) {
  html {
    font-size: 41%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 70%;
  }
}

body {
  font-family: Lato, "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  // background-color: var(--clr-primary-2);
}

// Utilities
.header-line {
  border-bottom: 2px solid black;
  width: 28rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  opacity: 0.5;
}

.blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(3px);
}

// Animations
@keyframes fadeUp {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
    transform: translateY(3rem);
  }
  66% {
    opacity: 0;
    transform: translateY(6rem);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeUp {
  0% {
    -moz-opacity: 0;
  }
  33% {
    -moz-opacity: 0;
    -moz-transform: translateY(3rem);
  }
  66% {
    -moz-opacity: 0;
    -moz-transform: translateY(6rem);
  }
  100% {
    -moz-opacity: 1;
  }
}
@-webkit-keyframes fadeUp {
  0% {
    -webkit-opacity: 0;
  }
  33% {
    -webkit-opacity: 0;
    -webkit-transform: translateY(3rem);
  }
  66% {
    -webkit-opacity: 0;
    -webkit-transform: translateY(6rem);
  }
  100% {
    -webkit-opacity: 1;
  }
}
@-o-keyframes fadeUp {
  0% {
    -o-opacity: 0;
  }
  33% {
    -o-opacity: 0;
    -o-transform: translateY(3rem);
  }
  66% {
    -o-opacity: 0;
    -o-transform: translateY(6rem);
  }
  100% {
    -o-opacity: 1;
  }
}
@-ms-keyframes fadeUp {
  0% {
    -ms-opacity: 0;
  }
  33% {
    -ms-opacity: 0;
    -ms-transform: translateY(3rem);
  }
  66% {
    -ms-opacity: 0;
    -ms-transform: translateY(6rem);
  }
  100% {
    -ms-opacity: 1;
  }
}
