.experience {
  margin: 10rem 10rem;

  &-header {
    color: var(--clr-secondary-1);
    margin-top: 5rem;
    margin-bottom: 15rem;
    text-align: center;
    font-size: 3rem;
    animation: FadeIn 1.5s ease-in;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5rem;
    flex-wrap: wrap;

    img {
      width: 20rem;
    }
  }
}

.img-container {
  display: flex;
  justify-content: center;
  width: 30rem;
  margin: 5rem 0;
}

@media (max-width: 500px) {
  .experience-header {
    font-size: 4rem;
  }
}
