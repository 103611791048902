.footer {
  background-color: var(--clr-primary-1);
  color: var(--clr-primary-2);
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-logo {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1644686001/staytonEngineering/SElogo-800_qynby1.jpg);
    width: 25rem;
    height: 6.5rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  &-details {
    padding-top: 5rem;
    text-align: center;

    &-address {
      margin-bottom: 2rem;
    }
    &-phone {
      margin-bottom: 1rem;
    }

    a,
    a:visited {
      color: var(--clr-primary-2);
    }
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  margin: 0 3rem;
  font-size: 1.3rem;

  a,
  a:visited {
    color: var(--clr-primary-2);
  }
}

@media (max-width: 1000px) {
  .footer-logo {
    width: 28rem;
    background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1644686472/staytonEngineering/SElogo-500-cropped_vyx2og.jpg);
  }
}

@media (max-width: 500px) {
  .footer {
    &-details {
      padding-top: 5rem;

      &-address {
        margin-bottom: 2rem;
      }
      &-phone {
        margin-bottom: 1rem;
      }

      h5 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 2rem;
      }
    }
  }
  .footer-bottom {
    font-size: 1.5rem;
    flex-direction: column;
    margin-bottom: 1rem;
  }
}
