.side-nav-modal {
  position: fixed;
  top: 7rem;
  height: 100%;
  width: 100%;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transform: translate(100%);
  -moz-transform: translate(100%);
  -webkit-transform: translate(100%);
  -ms-transform: translate(100%);
  -o-transform: translate(100%);
  backdrop-filter: blur(3px);
}

.side-nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 55%;
  background-color: var(--clr-primary-1);
  color: var(--clr-primary-2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  opacity: 1;
  font-size: 4rem;
  opacity: 0.99;

  &-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    cursor: pointer;
    margin-bottom: 20rem;

    &-link {
      margin-bottom: 3rem;
      display: inline-block; // Needed so padding will and shifting right animation will work
      padding: 1rem 2rem;
      text-decoration: none;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        var(--clr-primary-2) 50%
      );
      background-size: 235%; // Needed to make the below animation work.  Shifting the bg color for the animation.
      transition: all 0.5s;
      -moz-transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;

      &:hover,
      &:active {
        border-radius: 2px;
        background-position: 100%;
        color: var(--clr-secondary-1);
        transform: translateX(1rem);
        -moz-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        -webkit-transform: translateX(1rem);
        -o-transform: translateX(1rem);
      }
    }
  }
}

.show-side-nav-modal {
  visibility: visible;
  z-index: 10;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
}
