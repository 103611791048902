.contact {
  margin: 10rem 10rem;

  &-header {
    color: var(--clr-secondary-1);
    margin-top: 5rem;
    margin-bottom: 10rem;
    text-align: center;
    font-size: 3rem;
    animation: FadeIn 1.5s ease-in;
  }

  &-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-p {
      margin-bottom: 5rem;
      font-size: 1.8rem;
    }

    h3 {
      color: var(--clr-primary-1);
      margin-bottom: 3rem;
      font-size: 2.8rem;
    }
    h4 {
      color: var(--clr-primary-1);
      margin-bottom: 1rem;
      font-size: 2rem;
    }

    a,
    a:visited {
      text-decoration: none;
      margin-bottom: 5rem;
    }

    &-email {
      width: 10rem;
      border-radius: 3px;
      padding: 1rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 1rem var(--clr-primary-1);
      }

      &:active {
        transform: translateY(0.5px);
        box-shadow: 0 1rem 1rem var(--clr-primary-1);
      }
    }
  }
}

@media (max-width: 500px) {
  .contact {
    &-header {
      font-size: 4rem;
    }

    &-details {
      &-p {
        font-size: 2.5rem;
      }
      &-email {
        width: 10rem;
        padding: 1.5rem 2.5rem;
        font-size: 2.2rem;
      }
    }
    h3 {
      font-size: 3.5rem;
    }
    h4 {
      font-size: 3rem;
    }
    a {
      font-size: 2.5rem;
    }
  }
}
