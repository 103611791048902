// Hero
.hero {
  height: 92vh;
  z-index: 1;
  background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643921044/staytonEngineering/heroBg1920_fgymvw.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
}

@media (max-width: 1100px) {
  .hero {
    background-attachment: scroll;
    height: 95vh;
    background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643929980/staytonEngineering/heroBg1200_wdof4q.jpg);
  }
}
