.tagline {
  margin: 20rem 0;
  font-size: 3rem;
  text-align: center;
  color: var(--clr-primary-1);
  background-color: rgb(238, 238, 238);
  padding: 7rem;

  h1 {
    margin: 7rem 0;
  }
}

@media (max-width: 500px) {
  .tagline {
    padding: 7rem 4rem;
  }
}
