.hero-header {
  color: var(--clr-primary-2);
  width: 75%;
  height: 55%;
  animation: FadeIn 1.5s ease-in;

  h2 {
    font-size: 7rem;
    margin-bottom: 1rem;
    text-shadow: 3px 3px black;
  }

  h1 {
    font-size: 7rem;
    margin-bottom: 3rem;
    text-shadow: 3px 3px black;
  }

  p {
    line-height: 1.3;
    font-size: 2.2rem;
    width: 65%;
    text-shadow: 1px 1px black;
  }
}

.learn-more {
  border: none;
  border-radius: 3px;
  margin-top: 7rem;
  padding: 1rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem black;
  }

  &:active {
    transform: translateY(0.5px);
    box-shadow: 0 1rem 2rem black;
  }
}

// Animation

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes FadeIn {
  0% {
    -moz-opacity: 0;
  }
  100% {
    -moz-opacity: 1;
  }
}
@-webkit-keyframes FadeIn {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}
@-o-keyframes FadeIn {
  0% {
    -o-opacity: 0;
  }
  100% {
    -o-opacity: 1;
  }
}
@-ms-keyframes FadeIn {
  0% {
    -ms-opacity: 0;
  }
  100% {
    -ms-opacity: 1;
  }
}

@media (max-width: 1445px) {
  .hero-header {
    height: 57%;

    p {
      font-size: 2.2rem;
      width: 85%;
      text-shadow: 1px 1px black;
    }
  }
}

@media (max-width: 500px) {
  .hero-header {
    width: 90%;
    height: 60%;

    h2 {
      font-size: 5.3rem;
      margin-bottom: 2rem;
      text-shadow: 3px 3px black;
    }

    h1 {
      font-size: 5.5rem;
      margin-bottom: 5rem;
      text-shadow: 3px 3px black;
    }
    p {
      font-size: 2.7rem;
      width: 100%;
      margin-bottom: 5rem;
      line-height: 1.4;
    }
  }
  .learn-more {
    margin-top: 7rem;
    padding: 1.5rem 2rem;
    font-size: 2rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
