.services {
  margin-bottom: 5rem;
  margin-top: 10rem;

  &-header {
    color: var(--clr-secondary-1);
    margin-top: 5rem;
    margin-bottom: 20rem;
    text-align: center;
    font-size: 3rem;
    animation: FadeIn 1.5s ease-in;
  }

  &-list {
    &-item {
      height: 45rem;
      background-color: var(--clr-primary-1);
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-attachment: fixed;
      color: var(--clr-primary-2);
      text-shadow: 2px 2px black;
      font-size: 2.3rem;
      margin-bottom: 0.2rem;

      &-1 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643915039/staytonEngineering/plc1920_cbqbyy.jpg);
      }
      &-2 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643912803/staytonEngineering/hmi1920_cnpryf.jpg);
      }
      &-3 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643914170/staytonEngineering/design1920_hm99cf.jpg);
      }
      &-4 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643928596/staytonEngineering/panel1920_twt2ul.jpg);
      }
      &-5 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643911964/staytonEngineering/comDebug1920_krgtb1.jpg);
      }
      &-6 {
        background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643912797/staytonEngineering/onSite1920_hlnjwd.jpg);
      }

      h2 {
        margin-top: 2rem;
        font-size: 4rem;
      }
    }
  }
}

@media (max-width: 996px) {
  .services-list-item {
    background-attachment: scroll;

    &-1 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643916937/staytonEngineering/plc1000_rdthwf.jpg);
    }
    &-2 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643916937/staytonEngineering/hmi1000_miabzg.jpg);
    }
    &-3 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643916937/staytonEngineering/design1000_k9ub1g.jpg);
    }
    &-4 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643928599/staytonEngineering/panel1000_mrp0y7.jpg);
    }
    &-5 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643911964/staytonEngineering/comDebug1000_lne3zz.jpg);
    }
    &-6 {
      background-image: url(https://res.cloudinary.com/dfsqn3oi6/image/upload/v1643916937/staytonEngineering/onSite1000_tk94d0.jpg);
    }
  }
}

@media (max-width: 768px) {
  .services-list-item {
    h2 {
      font-size: 6rem;
    }
  }
}

@media (max-width: 500px) {
  .services-header {
    margin-top: 5rem;
    margin-bottom: 20rem;
    font-size: 4rem;
  }
  .services-list-item {
    height: 30rem;
    h2 {
      font-size: 6rem;
      text-align: center;
    }
  }
}
